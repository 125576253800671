<template>
    <div>
        <v-dialog v-model="userManualDialog" width="1000">
            <v-toolbar>Subcon User Manual</v-toolbar>
                <iframe 
                src="https://s3.ap-southeast-1.amazonaws.com/kansen.ihs-w.com/1.FIlesAndPdf/SubconUserManual.pdf" 
                height="900px">
                </iframe>
        </v-dialog>

        <v-dialog v-model="accountManualDialog" width="1000">
            <v-toolbar>Account Modification Manual</v-toolbar>
            
                <iframe 
                src="https://s3.ap-southeast-1.amazonaws.com/kansen.ihs-w.com/1.FIlesAndPdf/ACCOUNTSETTINGMANUAL.pdf" 
                height="900px">
                </iframe>
        </v-dialog>
    </div>
</template>

<script>
import { bus } from '../main'
    export default {
        data() {
            return {
                userManualDialog:false,
                accountManualDialog:false
            }
        },
        mounted () {
            bus.$on('userManualDialog', () =>{
            this.userManualDialog = true;
            })

            bus.$on('accountManualDialog', () =>{
            this.accountManualDialog = true;
            })
        },
    }
</script>

<style lang="scss" scoped>

</style>